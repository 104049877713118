.btnwallet {
	font-size: 1rem;
  align-items: center;
	align-self: flex-end;
  background-color: white;
  border-radius: 3.125em;
  box-shadow: 0em 0.3125em 0.3125rm rgba(74, 92, 103, 0.1);
  display: flex;
  height: 3.75em;
  justify-content: flex-end;
  margin-bottom: 0.4375em;
  min-width: 17.9375em;
  padding: 0 1.3188em;
}
.btnwalletlabel {
  align-items: center;
  display: flex;
  min-width: 15.1875em;
}
.btnwalletlabel .overlap-group {
  height: 1rem;
  margin-top: -0.0625rem;
  position: relative;
  width: 1.6875rem;
}
