@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("./fonts.css");

.nftpage {
	font-size: 1rem;
  align-items: center;
  background-image: url(../assets/landing-page_1x.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}
.nftpage .about {
  letter-spacing: 0em;
  line-height: 1.6em;
  margin-bottom: 0.3125em;
  margin-left: 3.25em;
  min-height: 1.625em;
  white-space: nowrap;
  width: 3.3125em;
}
.nftpage .artist {
  letter-spacing: 0.0056em;
  line-height: 2.8em;
  white-space: nowrap;
}
.nftpage .artist-1 {
  height: 1.875em;
  left: 0em;
  letter-spacing: 0.0056em;
  line-height: 1.8em;
  white-space: nowrap;
  width: 12.5em;
}
.nftpage .btnmint-plant {
  align-items: flex-start;
  display: flex;
  margin-top: 2.625em;
  min-width: 8.4375em;
}
.nftpage .btnmint-plant-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 1.4375em;
  min-width: 10.3125em;
}
.nftpage .category-type {
  min-height: 1.375em;
  white-space: nowrap;
}
.nftpage .category-type-1 {
  height: 1.375em;
  letter-spacing: 0.0088em;
  line-height: 1.4em;
  margin-top: 0.5625em;
  min-height: 1.375em;
  white-space: nowrap;
}
.nftpage .description {
  line-height: 1.8em;
  margin-top: 1.8125em;
  min-height: 7.5em;
  width: 32.625em;
}
.nftpage .details {
  align-items: flex-start;
  display: flex;
	flex-wrap: wrap;
  margin-top: 7.4375em;
	max-width: 84.25em;
}
.nftpage .edition {
  min-height: 1.0625em;
  white-space: nowrap;
}
.nftpage .edition-1 {
  height: 1em;
  letter-spacing: 0.0063em;
  line-height: 1em;
  margin-top: 0.0625em;
  min-height: 1em;
  white-space: nowrap;
}
.nftpage .flex-col {
	margin-right: 4.0625em;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 38.5625em;
}
.nftpage .flex-row {
	flex-wrap: wrap;
	justify-content: space-between;
  align-items: flex-start;
  display: flex;
  margin-top: 4.625em;
}
.nftpage .image {
  height: 37.125em;
  object-fit: cover;
  width: 38em;
}
.nftpage .image-1 {
  height: 24.0625em;
  object-fit: cover;
  width: 24.0625em;
}
.nftpage .meta {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 34em;
  width: 41.625em;
	margin-top: 1.5em;
}
.nftpage .meta-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 0.1456em;
  margin-top: 2.75em;
  min-height: 11.375em;
  width: 24.125em;
}
.nftpage .mint {
  height: 1.8125em;
  letter-spacing: 0em;
  line-height: 1.8em;
  min-height: 1.8125em;
  text-align: center;
  white-space: nowrap;
}
.nftpage .mint-1 {
  height: 1.875em;
  letter-spacing: 0em;
  line-height: 1.8em;
  min-height: 1.875em;
  text-align: center;
  white-space: nowrap;
  width: 5.875em;
}
.nftpage .mint-nf-ts {
  letter-spacing: 0em;
  line-height: 1.6em;
  margin-bottom: 0.3125em;
  margin-left: 2.0625em;
  min-height: 1.625em;
  white-space: nowrap;
  width: 5.25em;
}
.nftpage .mint-price {
  line-height: 1.8em;
  margin-top: 2.0625em;
  white-space: nowrap;
}
.nftpage .mint-price-1 {
  height: 1.875em;
  letter-spacing: 0.0056em;
  line-height: 1.8em;
  margin-top: 1.5625em;
  min-height: 1.875em;
  white-space: nowrap;
}
.nftpage .more-like-this {
  /* height: 6.0625em; */
  letter-spacing: 0em;
  line-height: 3.25em;
  /* min-height: 6.0625em; */
}
.nftpage .name {
  letter-spacing: 0em;
  /* line-height: 3.25em; */
	padding: 0.25em 0;
}
.nftpage .name-1 {
  line-height: 2.4em;
}
.nftpage .overlap-group {
  height: 1em;
  margin-top: -0.0625em;
  position: relative;
  width: 1.6875em;
}
.nftpage .overlap-group-1 {
  align-items: flex-end;
  background-color: var(--wild-strawberry);
  border-radius: 3.125em;
  display: flex;
  height: 3.8125em;
  justify-content: flex-end;
  min-width: 10.1875em;
  padding: 0.9563em 2.1563em;
}
.nftpage .overlap-group-2 {
  height: 7.625em;
  margin-top: 0.1875em;
  position: relative;
  width: 38.4375em;
}
.nftpage .overlap-group-3 {
  align-items: flex-end;
  background-color: var(--wild-strawberry);
  border-radius: 3.125em;
  display: flex;
  height: 3.75em;
  justify-content: flex-end;
  min-width: 8.3125em;
  padding: 0.9375em 1.7312em;
}
.nftpage .overlap-group1-1 {
  height: 4.875em;
  position: relative;
  width: 23.625em;
}
.nftpage .place {
  letter-spacing: 0em;
  line-height: 1.6em;
  margin-bottom: 0.3125em;
  margin-left: 3.75em;
  min-height: 1.625em;
  white-space: nowrap;
  width: 4.125em;
}
.nftpage .related {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 7.8125em;
  min-height: 55em;
}
.nftpage .related-seed {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 43.5em;
  width: 24.0625em;
	margin-right: 4.0625em;
	margin-bottom: 12.5em;
}
.nftpage .statstable {
	width: 38.5625em;
	margin-top: 3em;
}
.nftpage .statstable table {
	width: 100%;
	table-layout: fixed;
	text-align: left;
}
.nftpage .statstable th.col1 {
	width: 36%;
}
.nftpage .statstable table td.col1 {
	color: var(--deep-space-sparkle);
	font-family: Avenir-Medium,Helvetica;
	font-size: 1.125em;
	font-style: normal;
	font-weight: 500;
	position: relative;
  left: 0em;
  letter-spacing: 0em;
  line-height: 1.8em;
  top: 0em;
}
.nftpage .statstable table td.col2 {
	position: relative;
	left: 0;
  color: var(--deep-space-sparkle);
  font-family: "Avenir-Medium", Helvetica;
  font-size: 0.6875em;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.7188em;
  vertical-align: top;
  width: 25.375em;
}
.nftpage .details .text-1, .nftpage .related .text-1 {
  height: 1.875em;
  letter-spacing: 0em;
  line-height: 1.8em;
  min-height: 1.875em;
  text-align: center;
  white-space: nowrap;
  width: 5.875em;
}
.nftpage .details .text-2, .nftpage .related .text-2 {
  height: 1.875em;
  letter-spacing: 0em;
  line-height: 1.8em;
  min-height: 1.875em;
  text-align: center;
  white-space: nowrap;
  width: 5.875em;
}
.nftpage .details .text-3, 
.nftpage .related .text-3 {
  left: 0em;
  letter-spacing: 0em;
  line-height: 1.8em;
  position: absolute;
  top: 0em;
  width: 25.375em;
}
.nftpage .details .text-4, 
.nftpage .related .text-4 {
  color: var(--deep-space-sparkle);
  font-family: "Avenir-Medium", Helvetica;
  font-size: 0.6875em;
  font-weight: 500;
  left: 13.0625em;
  letter-spacing: 0em;
  line-height: 1.7188em;
  position: absolute;
  top: 0.25em;
  width: 25.375em;
}
.nftpage .details .text-5, .nftpage .related .text-5 {
  line-height: 1.8125em;
  white-space: nowrap;
}
.nftpage .details .text-6, .nftpage .related .text-6 {
  height: 1.625em;
  letter-spacing: 0.005em;
  line-height: 1.6em;
  min-height: 1.625em;
  white-space: nowrap;
  width: 12.6875em;
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 683px) {
	.nftcontent {
		width: 95%;
	}
	.nftpage .btnmint-plant {
    margin-top: 1.625em;
	}
	.nftpage .flex-col {
		margin: 0;
		width: auto;
	}
	.nftpage .meta { 
		font-size: 4vw;
		width: auto;
	}
	.nftpage .meta .description { 
		width: 100%;
	}
	.nftpage .meta-1 {
		margin-top: 0;
	}
	.nftpage .mint-price {
    margin-top: 1.0625em;
	}
	.nftpage .more-like-this { 
		width: auto;
		font-size: 9vw;
	}
	.nftpage .overlap-group1-1 {
    height: 100%;
	}
	.nftpage .related-seed {
		font-size: 4vw;
		margin-bottom: 5.5em;
	}
	.nftpage .statstable {
		font-size: 3.5vw;
		margin-top: 1em;
	}
	.nftpage .statstable th.col1 {
		width: 50%;
	}
}
