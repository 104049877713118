@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("./circle.css");

.ppod { 
  display: flex;
  align-items: flex-start;
}

.ppod .flex-row { 
  align-items: flex-start;
  display: flex;
  height: 100%;
}

.ppod .tree {
  position: absolute;
	width: 100%;
	height: 100%;
}
.ppod .canopy {
  position: absolute;
  width: auto;
}
.ppod .trunk {
  background-color: black;
  position: absolute;
  width: 0.75%;
}

.ppod .ground {
  background-color: black;
  height: 1%;
  bottom: 0;
  width: 100%;
}

.ppod .overlap-group,
.hpod .overlap-group {
  height: 100%;
  width: 100%;
  position: relative;
}
