@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("./fonts.css");
@import url("./walletComponents.css");

body {
	font-size: 1.5vw;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
	align-items: center;
  min-width: 39.3125rem;
}
.navbar {
	position: relative;
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
  align-items: center;
	width: 100%;
	max-width: 81.25rem;
	padding-top: 2.875rem;
	align-items: flex-start;
}
.navbar .plantary-logo {
  width: 4.6875em;
  height: 4.6875em;
}
.nav-menu .responsive-show {
	display: none; 
}
.loginarrow {
  height: 1rem;
  top: 0rem;
  width: auto;
}
.right-arrow {
  align-items: flex-start;
  display: flex;
  height: 0.875rem;
  justify-content: flex-end;
  margin-left: 0.8125rem;
  min-width: 1.5rem;
}
.navbar .text-1 {
  height: 1.625rem;
  letter-spacing: 0.005rem;
  line-height: 1.6rem;
  min-height: 1.625rem;
  white-space: nowrap;
  width: 12.6875rem;
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 1400px) {
	html {
		font-size: 75%; 
		-webkit-text-size-adjust:none; 
	}
}
@media screen and (max-width: 1027px) {
	html {
		font-size: 50%; 
		-webkit-text-size-adjust:none; 
	}
}
@media screen and (max-width: 683px) {
	html {
		font-size: 2.5vw;
	}
	.btnwallet {
		font-size: 3vw;
		align-self: center;
	}
	.nav-menu .responsive-show {
		display: block;
	}
	.nav-menu .responsive-hide {
		display: none;
	}
	.navbar { 
		font-size: 4vw;
		flex-direction: row-reverse;
		max-width: 90%;
		min-height: 9em;
	}
	.nav-menu {
		position: absolute;
		left: 0;
		top: 2.3em;
    padding: 0.625em;
		min-width: 0;
		flex-direction: column;
		background-color: var(--eucalyptus);
		z-index: 10;
		border-radius: 2.1875em;
	}
	.nav-menu .home-button {
		display: none;
	}
  .nav-menu .nav-menu-item {
		display: none; 
	}
	.nav-menu .menu-icon {
		display: block;
	}
	.nav-menu.responsive .nav-menu-item {
		display: block;
		margin: 1.25em 1.25em;
		color: #fff;
	}
}
