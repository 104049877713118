@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("./fonts.css");

.gardenpage {
	font-size: 1rem;
  align-items: center;
  background-size: 100% 100%;
  background-image: url(../assets/gardenbg1.png);
  display: flex;
  flex-direction: column;
  overflow: hidden;
	min-height: 100vw;
}
.gardenpage .gardens {
	font-size: clamp(0.1rem, 1.2vw, 1.2rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.8125rem;
	background-image: url(../assets/gardenbg2.svg);
	background-size: cover;
	width: 100%;
}
.gardenpage .garden {
  align-items: flex-start;
  display: flex;
	margin: 1.4375rem 11rem;
}
.gardenpage .ellipse-76-2 {
  height: 12.625rem;
  left: 0rem;
  position: absolute;
  top: 6.4375rem;
  width: 12.6875rem;
}
.gardenpage .fancy-garden {
  align-items: flex-start;
  display: flex;
  margin-right: 0.75rem;
  min-width: 83.625rem;
}
.gardenpage .plantrow {
}
.gardenpage .harvestrow {
}
.gardenpage .flex-row-1 {
  align-items: flex-start;
  display: flex;
  margin-right: 0.2481rem;
  min-width: 32.8125rem;
}
.gardenpage .garden-base {
  height: 8.25rem;
  left: 0rem;
  position: absolute;
  top: 28.5625rem;
  width: 83.5rem;
}
.gardenpage .garden-name {
  height: 11.5rem;
  left: 22.75rem;
  letter-spacing: 0rem;
  line-height: 4.4rem;
  position: absolute;
  top: 27rem;
  width: 40.4375rem;
}
.gardenpage .greeting {
	text-align: center;
  height: 11.5rem;
  letter-spacing: 0rem;
  line-height: 3.3rem;
	margin: 1.375rem auto 0 auto;
  min-height: 11.5rem;
  width: 40.4375rem;
}
.gardenpage .ground-line {
  background-color: black;
  height: 0.4375rem;
  left: 2.8125rem;
  position: absolute;
  top: 28.25rem;
  width: 73rem;
}
/* chrome has a rendering glitch when border width is in ems */ 
.gardenpage .hpod .stem,
.gardenpage .ppod .trunk {
	width: 0.25rem !important;
}
.gardenpage .hpod .circle,
.gardenpage .ppod .circle {
	border: 0.25rem solid #000 !important;
}

.gardenpage .fancy-garden .hpod .ground {
	width: 103%;
}
.gardenpage .hpod .flex-row,
.gardenpage .ppod .flex-row {
	justify-content: center;
}
.gardenpage .harvest-pod {
  align-items: flex-end;
  display: flex;
  left: 6.5625em;
  height: 19.1875em;
  top: 28.375em;
}
.gardenpage .overlap-group-5 {
  position: relative;
  width: 75.8125em;
}
.gardenpage .overlap-group9 {
  height: 56.125rem;
  position: relative;
  width: 83.5rem;
}
.gardenpage .place {
  letter-spacing: 0rem;
  line-height: 1.6rem;
  margin-bottom: 0.3125rem;
  margin-left: 3.75rem;
  min-height: 1.625rem;
  white-space: nowrap;
  width: 4.125rem;
}
.gardenpage .plant-pod {
  height: 28.5625em;
  left: 3.1875em;
  top: 0em;
}
.gardenpage .text-1 {
  height: 1.625rem;
  letter-spacing: 0.005rem;
  line-height: 1.6rem;
  min-height: 1.625rem;
  white-space: nowrap;
  width: 12.6875rem;
}
.gardenpage .text-2 {
  letter-spacing: 0rem;
  line-height: 1.6rem;
  margin-bottom: 0.3125rem;
  margin-left: 3.25rem;
  min-height: 1.625rem;
  white-space: nowrap;
  width: 3.3125rem;
}
.gardenpage .text-3 {
  letter-spacing: 0rem;
  line-height: 1.6rem;
  margin-bottom: 0.3125rem;
  margin-left: 2.0625rem;
  min-height: 1.625rem;
  white-space: nowrap;
  width: 5.25rem;
}
.gardenpage .text-4 {
  letter-spacing: 0rem;
  line-height: 1.6rem;
  margin-bottom: 0.3125rem;
  margin-left: 4.875rem;
  min-height: 1.875rem;
  white-space: nowrap;
  width: 7.0625rem;
}
.gardenpage .vector {
  height: 1rem;
  margin-bottom: 0.0313rem;
  margin-left: 1.1875rem;
  width: 1.625rem;
}
.gardenpage .weather-moon {
  height: 1.8125rem;
  margin-bottom: 0.0625rem;
  margin-left: 1rem;
  width: 1.25rem;
}
.gardenpage .weather-sun {
  height: 2.125rem;
  width: 2.125rem;
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
a {
  text-decoration: none;
  display: contents;
}
