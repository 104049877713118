@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("./circle.css");

.harvests {
  align-items: flex-start;
  display: flex;
  margin-right: 0.75%;
	height: 100%;
}
.hpod .circle { 
  position: absolute;
  width: auto;
}

.hpod .stem {
  background-color: black;
  width: 3%;
  position: absolute;
	top: 0;
}

.hpod .ground {
  background-color: black;
  height: 1.5%;
  left: 0px;
  width: 100%;
	top: 0;
}


