@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("./fonts.css");

.landingpage {
	font-size: 1rem;
  align-items: center;
  background-image: url(../assets/landing-page_1x.png);
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
	margin-bottom: 3.125rem;
}
.landingpage .btnmint-plant- {
  align-items: flex-end;
  background-color: var(--wild-strawberry);
  border-radius: 3.125rem;
  display: flex;
  height: 3.75rem;
  justify-content: flex-end;
  left: 5.5rem;
  min-width: 10.6875rem;
  padding: 0.9437rem 3.9562rem;
  position: absolute;
  top: 31.625rem;
}
.landingpage .btnmint-plant-money {
  align-items: flex-end;
  background-color: var(--absolute-zero);
  border-radius: 3.125rem;
  display: flex;
  height: 3.75rem;
  justify-content: flex-end;
  left: 5.5625rem;
  min-width: 10.6875rem;
  padding: 0.9437rem 3.9562rem;
  position: absolute;
  top: 31.625rem;
}
.landingpage .btnmint-plant-shrubbery {
  align-items: flex-end;
  background-color: var(--eucalyptus);
  border-radius: 3.125rem;
  display: flex;
  height: 3.75rem;
  justify-content: flex-end;
  left: 5.5rem;
  min-width: 10.6875rem;
  padding: 0.9437rem 3.9562rem;
  position: absolute;
  top: 31.625rem;
}
.landingpage .btnstart-minting {
  align-items: flex-start;
  display: flex;
  margin-top: 1.875em;
  min-width: 9.8125em;
}
.landingpage .category-bg {
  background-color: var(--science-blue);
  border-radius: 3.125rem;
  height: 35.375rem;
  left: 0rem;
  position: absolute;
  top: 3.75rem;
  width: 37.875rem;
}
.landingpage .category-bg-1 {
  background-color: var(--magenta);
  border-radius: 3.125rem;
  height: 35.375rem;
  left: 0rem;
  position: absolute;
  top: 3.75rem;
  width: 37.875rem;
}
.landingpage .category-bg-2 {
  background-color: var(--eucalyptus-2);
  border-radius: 3.125rem;
  height: 35.375rem;
  left: 0rem;
  position: absolute;
  top: 3.75rem;
  width: 37.875rem;
}
.landingpage .category-description {
  height: 16.9375rem;
  left: 5.5rem;
  position: absolute;
  top: 14.125rem;
  width: 30.0625rem;
}
.landingpage .category-description-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 5.5rem;
  min-height: 16.9375rem;
  position: absolute;
  top: 14.125rem;
  width: 30.0625rem;
}
.landingpage .category-details {
  left: 0rem;
  letter-spacing: 0rem;
  line-height: 1.8rem;
  position: absolute;
  top: 6.25rem;
  width: 29.875rem;
}
.landingpage .category-details-1 {
  letter-spacing: 0rem;
  line-height: 1.8rem;
  margin-left: 0rem;
  min-height: 10.6875rem;
  width: 29.875rem;
}
.landingpage .category-intro {
  margin-top: 2.8125rem;
  min-width: 37.875rem;
	margin-left: 1.425rem;
	margin-right: 1.425rem;
}
.landingpage .category-name {
  height: 6.25rem;
  left: 0rem;
  letter-spacing: 0rem;
  line-height: 3.25rem;
  position: absolute;
  top: 0rem;
  width: 29.9375rem;
}
.landingpage .category-name-1 {
  height: 6.25rem;
  letter-spacing: 0rem;
  line-height: 3.25rem;
  min-height: 6.25rem;
  width: 29.9375rem;
}
.landingpage .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin: 0 2rem;
  min-height: 81.0625rem;
}
.landingpage .front-garden {
  align-items: flex-start;
  display: flex;
	margin: 3.3125rem 3.6875rem;
  min-width: 33.875rem;
}
.landingpage .ground-line {
	background-color: #000;
	height: 0.125rem;
	left: 0;
	position: absolute;
	top: 12.625rem;
	width: 100%;
}
.landingpage .harvestpod {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 17.875rem;
  height: 8.5625rem;
  position: absolute;
  top: 12.625rem;
  width: 15.375rem;
}
.landingpage .harvestpod-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 3.25rem;
  height: 8.5625rem;
  position: absolute;
  top: 12.625rem;
  width: 15.375rem;
}
.landingpage .headline {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
	margin: 0 3.625rem;
  min-height: 27.75rem;
  width: 36.9375rem;
}
.landingpage .label {
  height: 1.8125rem;
  letter-spacing: 0rem;
  line-height: 1.8rem;
  min-height: 1.8125rem;
  text-align: center;
  white-space: nowrap;
  width: 2.5625rem;
}
.landingpage .mint {
  height: 1.8125rem;
  letter-spacing: 0rem;
  line-height: 1.8rem;
  min-height: 1.8125rem;
  text-align: center;
  white-space: nowrap;
  width: 2.5625rem;
}
.landingpage .overlap-group-1 {
  height: 4.6875rem;
  position: relative;
  width: 3.75rem;
}
.landingpage .overlap-group-2 {
  height: 8.5rem;
  margin-left: 0.125rem;
  position: relative;
  width: 5.625rem;
}
.landingpage .overlap-group-3 {
  height: 5.625rem;
  margin-left: 0.4375rem;
  position: relative;
  width: 4.6875rem;
}
.landingpage .overlap-group-5 {
  height: 39.125rem;
  margin-bottom: 0rem;
  position: relative;
  width: 37.875rem;
}
.landingpage .overlap-group-6 {
  align-items: flex-end;
  background-color: var(--eucalyptus);
  border-radius: 3.125em;
  display: flex;
  height: 3.75em;
  justify-content: flex-end;
  min-width: 9.6875em;
  padding: 0.9375em 1.525em;
}
.landingpage .overlap-group1 {
  height: 21.25rem;
  position: relative;
  width: 33.875rem;
}
.landingpage .overlap-group4 {
  height: 39.125rem;
  position: relative;
  width: 37.875rem;
}
.landingpage .plant-categories {
  align-items: flex-start;
  display: flex;
	flex-wrap: wrap;
  margin-top: 5.4375rem;
	max-width: 95.625rem;
}

.landingpage .ppod,
.landingpage .hpod {
	font-size: 0.45rem;
}

.landingpage .ppod {
  height: 12.625rem;
  min-width: 15.875rem;
  position: absolute;
  top: 0rem;
}
.landingpage .hpod .stem,
.landingpage .ppod .trunk {
  width: 0.125rem !important;
}
.landingpage .hpod .circle,
.landingpage .ppod .circle {
  border: 0.125rem solid #000 !important;
}

.landingpage .plant-pod {
  left: 0rem;
}
.landingpage .plant-pod-2 {
  left: 15.5rem;
}

.landingpage .plant-pod-1 {
  left: 5.3125rem;
}
.landingpage .plant-pod-3 {
  left: 5.125rem;
}

.landingpage .start-minting {
  height: 1.8125em;
  letter-spacing: 0em;
  line-height: 1.8em;
  min-height: 1.8125em;
  text-align: center;
  white-space: nowrap;
  width: 6.4375em;
}
.landingpage .headline .text-1 {
  letter-spacing: 0em;
  line-height: 1.8em;
  margin-top: 3.125em;
  min-height: 7.5em;
  width: 25.5em;
}
.landingpage .headline .text-2 {
  height: 11.5rem;
  letter-spacing: 0rem;
  line-height: 5.5rem;
  min-height: 11.5rem;
  width: 36.8125rem;
}
.landingpage .top-story {
  align-items: center;
  display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
  margin-top: 8.6875rem;
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
a {
  text-decoration: none;
  display: contents;
}

@media screen and (max-width: 683px) {
	.landingpage .top-story {
		margin-top: 6.6875rem;
	}
	.landingpage .headline .btnstart-minting, 
	.landingpage .headline .text-1 {
		font-size: 4vw;
	}
}
