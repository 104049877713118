@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

* {
  box-sizing: border-box;
}

:root {
  --absolute-zero: rgba(0, 53, 190, 1);
  --deep-space-sparkle: rgba(74, 92, 103, 1);
  --eucalyptus: rgba(66, 217, 153, 1);
  --eucalyptus-2: rgba(66, 217, 153, 0.15);
  --magenta: rgba(240, 99, 184, 0.15);
  --port-gore: rgba(33, 35, 83, 1);
  --science-blue: rgba(0, 98, 190, 0.15);
  --wild-strawberry: rgba(253, 44, 144, 1);
}

@font-face {
  font-family: "Helvetica-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.animaapp.com/Helvetica-Bold") format("truetype");
}

@font-face {
  font-family: "Avenir-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.animaapp.com/Avenir-Medium") format("opentype");
}

@font-face {
  font-family: "Avenir-Heavy";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.animaapp.com/Avenir-Heavy") format("truetype");
}

.avenir-heavy-normal-port-gore-16px {
  color: var(--port-gore);
  font-family: "Avenir-Heavy", Helvetica;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
}
.avenir-heavy-normal-port-gore-18px {
  color: var(--port-gore);
  font-family: "Avenir-Heavy", Helvetica;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 400;
}
.avenir-heavy-normal-white-18px {
  color: white;
  font-family: "Avenir-Heavy", Helvetica;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 400;
}
.avenir-medium-wild-strawberry-14px {
  color: var(--wild-strawberry);
  font-family: "Avenir-Medium", Helvetica;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 500;
}
.avenir-heavy-normal-wild-strawberry-18px {
  color: var(--wild-strawberry);
  font-family: "Avenir-Heavy", Helvetica;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 400;
}
.avenir-medium-deep-space-sparkle-10px {
  color: var(--deep-space-sparkle);
  font-family: "Avenir-Medium", Helvetica;
  font-size: 0.625em;
  font-style: normal;
  font-weight: 500;
}
.avenir-medium-deep-space-sparkle-16px {
  color: var(--deep-space-sparkle);
  font-family: "Avenir-Medium", Helvetica;
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
}
.avenir-medium-deep-space-sparkle-18px {
  color: var(--deep-space-sparkle);
  font-family: "Avenir-Medium", Helvetica;
  font-size: 1.125em;
  font-style: normal;
  font-weight: 500;
}
.avenir-heavy-normal-deep-space-sparkle-24px {
  color: var(--deep-space-sparkle);
  font-family: "Avenir-Heavy", Helvetica;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
}
.helvetica-bold-port-gore-40px {
  color: var(--port-gore);
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 2.5em;
  font-style: normal;
  font-weight: 700;
}
.helvetica-bold-port-gore-48px {
  color: var(--port-gore);
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 3em;
  font-style: normal;
  font-weight: 700;
}
.helvetica-bold-white-64px {
  color: white;
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 4em;
  font-style: normal;
  font-weight: 700;
}
.helvetica-bold-port-gore-80px {
  color: var(--port-gore);
  font-family: "Helvetica-Bold", Helvetica;
  font-size: 5em;
  font-style: normal;
  font-weight: 700;
}

